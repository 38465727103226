import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../widgets/Layout";
import { graphql } from "gatsby";
import { Card } from "react-bootstrap";

// markup
const DocumentationPage = ({ data }) => {
  return (
    <Layout>
      <section className="container pt-2 pb-5">
        <Card className="bg-white custom-card ">
          <Card.Body className="markdown-content">
            <MDXRenderer>{data?.page?.nodes[0]?.childMdx?.body}</MDXRenderer>
          </Card.Body>
        </Card>
      </section>
    </Layout>
  );
};

export default DocumentationPage;
export const query = graphql`
  query getPage {
    page: allFile(filter: { relativeDirectory: { eq: "documentation" } }) {
      nodes {
        childMdx {
          frontmatter {
            title
          }
          slug
          body
        }
      }
    }
  }
`;
